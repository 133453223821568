import React from 'react';
import { Link, graphql } from 'gatsby';
//import Img from 'gatsby-image'
import Layout from '../components/layout';
import Definitions from '../common/definitions';

const CountryTemplate = ({ data }) => (
  <Layout>
    <h1>{data.strapiCountries.StatesWord} in {data.strapiCountries.CountryName}</h1>
    <div><Link to={Definitions.GalleryPagesBasePath}>zurück</Link></div>
    
    <div>
      <ul>
        {data.strapiCountries.states.map(
            (state) => {
            return(
              <li key={state.id}>
                <Link to={Definitions.GalleryPagesBasePath.concat(data.strapiCountries.slug.toLowerCase(),'/',state.slug.toLowerCase(),'/')}>
                  {state.StateName}
                </Link>&nbsp;({getNumberOfCitiesInState(data, state.id)})
              </li>
            );
          })
        }
      </ul>
    </div>
  </Layout>
);

export default CountryTemplate;

const getNumberOfCitiesInState = (data, stateId) => {
  let count = 0;
  for (let cityIdx = 0; cityIdx < data.allStrapiCities.edges.length; cityIdx++) {
    if (data.allStrapiCities.edges[cityIdx].node.district.state === stateId) {
      count++;
    }
  }
  return count;
}

export const query = graphql`
  query CountryTemplate($id: String!) {
    strapiCountries(id: {eq: $id}) {
      CountryName
      StatesWord
      strapiId
      id
      slug
      states {
        StateName
        id
        slug
      }
    }
    allStrapiCities {
      edges {
        node {
          id
          district {
            id
            state
          }
        }
      }
    }
  }
`;